import React from "react";
import Building from "../../models/Building";
import SchoolSelectMeh from "./SchoolSelectMeh";
import clsx from "clsx";
import { View } from "../../types/types";
import CenterAll from "../util/CenterAll";
import { getFilteredRooms, getSearchedRooms } from "../../helpers/filter_helpers";
import { getModels, store } from "../../constants/globals";
import { friendlySeason, intersperseBuildings } from "../../util/app_util";
import { getStatsForBuilding, InstallStats } from "../../helpers/stats_helpers";
import JobsList from "./JobsList";
import sortBy from "lodash/sortBy";

interface JobsViewProps {
  viewShown: View;
}

export type BuildingStats = {
  [building_id: number]: InstallStats;
};

let rendered = false;

export default function JobsView(props: JobsViewProps): JSX.Element | null {
  const search = store.useState("search");

  React.useEffect(() => {
    return (): void => {
      rendered = true;
    };
  }, []);

  const { viewShown } = props;
  const filter = window.App.filter;

  if (viewShown === "Jobs") {
    rendered = true;
  } else if (!rendered) {
    return null;
  }

  if (filter) {
    const onListPage = viewShown === "Jobs";
    const classes = clsx({
      hidden: viewShown === "JobDetail" || viewShown === "BuildingDetail",
    });

    const school = filter.getSchool();

    let rooms = search && search.trim() ? getSearchedRooms(search) : getFilteredRooms(filter);
    rooms = rooms.filter((r) => r.installs && r.installs.length > 0);

    if (filter.jobSearchType === "room number, then wing letter") {
      rooms = sortBy(rooms, ["number", "wing"]);
    } else {
      rooms = sortBy(rooms, ["wing", "number"]);
    }

    const buildings = getModels("buildings").filter((b: Building) => b.school_id === school.id);

    const building_stats: BuildingStats = {};
    const rooms_w_buildings = intersperseBuildings(rooms, buildings);

    for (let index = 0; index < rooms_w_buildings.length; index++) {
      const room_or_building = rooms_w_buildings[index];

      if (room_or_building.isBuilding()) {
        const building = room_or_building;
        building_stats[building.id] = getStatsForBuilding(building, rooms);
      }
    }

    if (onListPage) {
      const subsubtitle = `${rooms.length} rooms in list`;

      setTimeout(() => {
        const state = store.state;
        state.title = school.name + ` (${friendlySeason()})`;
        const building = rooms_w_buildings[0] as Building | undefined;
        if (building) {
          state.subtitle = building.shown_name;
        }
        state.subsubtitle = subsubtitle;

        store.forceUpdate("title");
      }, 1);
    }

    return (
      <div className={classes}>
        <JobsList rooms_w_buildings={rooms_w_buildings} building_stats={building_stats} />
      </div>
    );
  } else {
    setTimeout(() => {
      const state = store.state;
      state.title = "Jobs";
      state.subtitle = state.subsubtitle = undefined;
      store.forceUpdate("title");
    }, 1);

    return (
      <CenterAll className="mt-4">
        <SchoolSelectMeh />
      </CenterAll>
    );
  }
}
