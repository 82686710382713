import PayscalesTable from "./PayscalesTable";
import { EarningsPageProps } from "./EarningsPage";
import strftime from "strftime";
import TotalsTable from "./TotalsTable";
import MoneyTable from "./MoneyTable";
import sortBy from "lodash/sortBy";
import { store, router } from "../../constants/globals";
import {
  getStatsForUserOnDay,
  getMoneyForStats,
  computeRoomTotal,
  computeBonusBuckTotalOnDay,
  getTruckLoadTotalForUserAndDate,
  getDaysWorked,
  actionsForUserAndDate,
} from "../../helpers/stats_helpers";
import { andJoin, getDateStr } from "../../util/util";
import AppDatePicker from "../selects/AppDatePicker";
import { Divider, Typography } from "@material-ui/core";
import UserStatsTable from "./StatsTable";
import { Activity } from "../../models/InstallAction";
import User from "../../models/User";
import ChangeSeasonSelect from "../util/ChangeSeasonSelect";
import compact from "lodash/compact";

const { setPartialState } = store.getScopedHelpers("user_work_page");

const handleDateChange = (date: Date | null): void => {
  const d = date || new Date();
  setPartialState({ date: d });
};

const renderActions = (activities: Array<Activity>, user: User): JSX.Element | null => {
  if (activities.length) {
    return (
      <div className="mt-2">
        <Typography variant="h6">Activity ({activities.length})</Typography>
        <div className="pl-1">
          {activities.map((a) => (
            <div
              className="job-row pl-1 py-2"
              key={a.key}
              onClick={(): void => router.go(`/workers/${user.id}/rooms/${a.room_id}`)}
            >
              {a.txt}
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const DailyEarnings = (props: EarningsPageProps): JSX.Element => {
  const { user, date } = props;

  if (date) {
    const stats = getStatsForUserOnDay(user, date);

    const midyear =
      store.state.midyear_user_ids!.includes(user.id) &&
      getDateStr(date) >= store.state.school_year!.midyear_start_date;

    const money = getMoneyForStats(stats, user, midyear);

    const room_total = computeRoomTotal(money);

    const bonus_buck_total = computeBonusBuckTotalOnDay(user, date);

    const truck_load_total = getTruckLoadTotalForUserAndDate(user, date);

    const total = truck_load_total + room_total + bonus_buck_total * 100;

    const totals = {
      total,
      truck_load_total,
      room_total,
      bonus_buck_total,
    };

    const days = sortBy(getDaysWorked(user), (d) => d.date).map((d) => d.str);

    let activities = compact(actionsForUserAndDate(user, date).map((ia) => ia.toActivity()));
    activities = sortBy(activities, (a) => a.created_at);

    return (
      <div>
        <ChangeSeasonSelect />
        <div className="mt-2">{PayscalesTable({ user, midyear })}</div>
        <div className="mt-3">Days worked: {andJoin(days)}</div>
        <div className="mt-1 d-flex justify-content-between align-items-center">
          <div>{strftime("%b %e %Y", date)} earnings:</div>
          <AppDatePicker label="change date" date={date} onChangeDate={handleDateChange} />
        </div>

        <TotalsTable {...totals} />

        <MoneyTable money={money} />
        <Divider />
        <UserStatsTable stats={stats} />
        {renderActions(activities, user)}
      </div>
    );
  } else {
    return (
      <div>
        <span>Please select a date:</span>
        <AppDatePicker date={date} label="select date" onChangeDate={handleDateChange} />
      </div>
    );
  }
};

export default DailyEarnings;
