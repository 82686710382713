import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Product from "../../models/Product";
import sortBy from "lodash/sortBy";
import { shorts } from "../../constants/shorts";
import { getModels } from "../../constants/globals";

const renderProductOption = (product: Product): JSX.Element => {
  const key = `prod_option_${product.id}`;

  return (
    <option key={key} value={product.id}>
      {product.name} ({product.short})
    </option>
  );
};

export interface ProductIdsSelectProps {
  product_ids: number[] | null;
  onChange: (product_ids: number[]) => void;
  label: string;
}

const sortProductsByShort = (products: Product[]): Product[] => {
  return sortBy(products, (p) => {
    return shorts.indexOf(p.short);
  });
};

const handleChangeMultiple = (
  event: React.ChangeEvent<{ value: unknown }>,
  onChange: (product_ids: number[]) => void,
): void => {
  const { options } = event.target as HTMLSelectElement;
  const product_ids: number[] = [];
  for (let i = 0, l = options.length; i < l; i += 1) {
    if (options[i].selected) {
      product_ids.push(parseInt(options[i].value));
    }
  }

  onChange(product_ids);
};

export default function ProductIdsSelect(props: ProductIdsSelectProps): JSX.Element {
  const { product_ids, label, onChange } = props;

  const products = sortProductsByShort(getModels("products")).filter((p) => !p.archived);

  return (
    <div>
      <FormControl style={{ minWidth: 120 }}>
        <InputLabel shrink htmlFor="select-multiple-native">
          {label}
        </InputLabel>
        <Select
          multiple
          native
          value={product_ids}
          onChange={(e): void => handleChangeMultiple(e, onChange)}
        >
          {products.map(renderProductOption)}
        </Select>
      </FormControl>
    </div>
  );
}
