import React from "react";

import { InstallStats } from "../../helpers/stats_helpers";
import { short_order, shorts, stat_short_order, stats_shorts } from "../../constants/shorts";
import { install_status_colors } from "../../constants/colors";
import sortBy from "lodash/sortBy";
import { store } from "../../constants/globals";

function StatsTable(stats: InstallStats, title: string, all = false): JSX.Element {
  const key = `school_stats_${title}`;
  const needed_shorts = all
    ? stats_shorts
    : sortBy(Object.keys(stats), (short) => stat_short_order.indexOf(short));

  const color = store.getNonNullState("colormode") === "dark" ? "black" : "white";

  return (
    <div key={key} className="mt-2">
      <div>{title}</div>
      <table className="muhtable" style={{ borderSpacing: 0 }}>
        <thead>
          <tr>
            {needed_shorts.map((short) => (
              <th key={`stats_th_${short}_${title}`}>{short}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="total">
            {needed_shorts.map((short) => (
              <td key={`stats_th_${short}_${title}_total`}>{stats[short].total}</td>
            ))}
          </tr>

          <tr
            className="text_pending_delivery"
            style={{
              backgroundColor: install_status_colors.pending_delivery,
              color,
            }}
          >
            {needed_shorts.map((short) => (
              <td key={`stats_th_${short}_${title}_pending_delivery`}>
                {stats[short].pending_delivery}
              </td>
            ))}
          </tr>

          <tr
            className="text_delivered"
            style={{ backgroundColor: install_status_colors.delivered, color }}
          >
            {needed_shorts.map((short) => (
              <td key={`stats_th_${short}_${title}_delivered`}>{stats[short].delivered}</td>
            ))}
          </tr>

          <tr
            className="text_installed"
            style={{ backgroundColor: install_status_colors.installed, color }}
          >
            {needed_shorts.map((short) => (
              <td key={`stats_th_${short}_${title}_installed`}>{stats[short].installed}</td>
            ))}
          </tr>
          <tr
            className="text_pending_removal"
            style={{
              backgroundColor: install_status_colors.pending_removal,
              color,
            }}
          >
            {needed_shorts.map((short) => (
              <td key={`stats_th_${short}_${title}_pending_removal`}>
                {stats[short].pending_removal}
              </td>
            ))}
          </tr>
          <tr
            className="text_uninstalled"
            style={{
              backgroundColor: install_status_colors.uninstalled,
              color,
            }}
          >
            {needed_shorts.map((short) => (
              <td key={`stats_th_${short}_${title}_uninstalled`}>{stats[short].uninstalled}</td>
            ))}
          </tr>
          <tr
            className="text_removed"
            style={{ backgroundColor: install_status_colors.removed, color }}
          >
            {needed_shorts.map((short) => (
              <td key={`stats_th_${short}_${title}_removed`}>{stats[short].removed}</td>
            ))}
          </tr>
          <tr
            className="text_missing"
            style={{ backgroundColor: install_status_colors.missing, color }}
          >
            {needed_shorts.map((short) => (
              <td key={`stats_th_${short}_${title}_missing`}>{stats[short].missing}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default StatsTable;
