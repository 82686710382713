import StateRouter from "../libs/StateRouter";
import { Required } from "utility-types";
import { getStore, store, getModels } from "../constants/globals";
import { findModelById, friendlySeason } from "../util/app_util";
import { getBasicInfoFormState } from "../helpers/auth_helpers";
import { getFullProfileFormState } from "../helpers/profile_helpers";
import { AppState } from "../types/state_types";

export type NextRoute = {
  nextRoute: string;
};

const rootRoute = (): RouterState => {
  return { title: "Home", viewShown: "Home" };
};

const searchJobsRoute = (search: string): RouterState => {
  return {
    viewShown: "Jobs",
    search,
  };
};

const jobsRoute = (): RouterState => {
  // const { search } = queryString.parse(window.location.search) as {
  //   search: string;
  // };

  return { title: "Jobs", viewShown: "Jobs" };
};

const loginRoute = (): RouterState => {
  return { title: "Login", viewShown: "Login" };
};

const signupRoute = (): RouterState => {
  return { title: "Signup", viewShown: "Signup" };
};

const truckLoadsRoute = (): RouterState => {
  return { title: `Truck Loads (${friendlySeason()})`, viewShown: "TruckLoads" };
};

const payscalesRoute = (): RouterState => {
  return { title: "Payscales", viewShown: "PayscaleList" };
};

const ticketsRoute = (): RouterState => {
  return { title: "Tickets", viewShown: "TicketsList" };
};

const workersRoute = (): RouterState => {
  const workers_page = { worker_tab: 0 };
  return { title: "Workers", viewShown: "Workers", workers_page };
};

const bonusBucksRoute = (): RouterState => {
  return { title: `Bonus Bucks (${friendlySeason()})`, viewShown: "BonusBucks" };
};

const cheatSheetRoute = (): RouterState => {
  return { title: "Cheat Sheet", viewShown: "CheatSheet" };
};

const jobDetailRoute = (room_id_str: string): RouterState => {
  const room = findModelById(getModels("rooms"), parseInt(room_id_str));
  const title = room.building.school.name;

  return {
    title,
    room,
    viewShown: "JobDetail",
    drill_href: "/jobs",
  };
};

const workerRoomRoute = (worker_id_str: string, room_id_str: string): RouterState => {
  const user_id = parseInt(worker_id_str);
  const room = findModelById(getModels("rooms"), parseInt(room_id_str));
  const title = room.building.school.name;

  return {
    title,
    room,
    viewShown: "WorkerJobDetail",
    drill_href: `/workers/${user_id}/daily_earnings`,
  };
};

const ticketRoomRoute = (ticket_id_str: string): RouterState => {
  const ticket_id = parseInt(ticket_id_str);
  const ticket = findModelById(getModels("tickets"), ticket_id);
  const room = findModelById(getModels("rooms"), ticket.room_id);
  const title = room.building.school.name;

  return {
    title,
    room,
    viewShown: "JobDetail",
    drill_href: "/needs_attention_tickets",
  };
};

const buildingDetailRoute = (building_id_str: string): RouterState => {
  const building = findModelById(getModels("buildings"), parseInt(building_id_str));

  const building_detail = { building, tab: 0 };

  return {
    title: building.shown_name,
    viewShown: "BuildingDetail",
    drill_href: "/jobs",
    building_detail,
  };
};

const basicInfoRoute = (): RouterState => {
  const user = store.getNonNullState("currentUser");
  store.state.basic_info_form = getBasicInfoFormState(user);

  return { title: "Basic Info", viewShown: "BasicInfoForm" };
};

const truckLoadDetailRoute = (tl_id_str: string): RouterState => {
  const truck_load_detail = {
    working: false,
    truck_load: findModelById(getModels("truck_loads"), parseInt(tl_id_str)),
  };

  return {
    title: "Truck Load Detail",
    viewShown: "TruckLoadDetail",
    drill_href: "/truck_loads",
    truck_load_detail,
  };
};

const payscaleDetailRoute = (ps_id_str: string): RouterState => {
  const payscale = findModelById(getModels("payscales"), parseInt(ps_id_str));

  return {
    title: `Payscale Detail ${friendlySeason()}`,
    viewShown: "PayscaleDetail",
    drill_href: "/payscales",
    payscale,
  };
};

const yourWorkRoute = (): RouterState => {
  const user_work_page = {
    tab: 0,
    earned_tab: 0,
    user: store.getNonNullState("currentUser"),
    date: new Date(),
  };

  return { title: "Your Work", viewShown: "UserWork", user_work_page };
};

const workerRoute = (worker_id_str: string): RouterState => {
  const worker_id = parseInt(worker_id_str);
  const user = findModelById(getModels("users"), worker_id);
  const title = `${user.username}'s work (${friendlySeason()})`;

  const user_work_page = {
    tab: 0,
    earned_tab: 0,
    user: user,
    date: new Date(),
  };

  return { title, viewShown: "UserWork", user_work_page };
};

const workerRouteBonusBucks = (worker_id_str: string): RouterState => {
  const worker_id = parseInt(worker_id_str);
  const user = findModelById(getModels("users"), worker_id);
  const title = `${user.username}'s work (${friendlySeason()})`;

  const user_work_page = {
    tab: 1,
    earned_tab: 0,
    user: user,
    date: new Date(),
  };

  return { title, viewShown: "UserWork", user_work_page };
};

const workerRouteDailyEarnings = (worker_id_str: string): RouterState => {
  const worker_id = parseInt(worker_id_str);
  const user = findModelById(getModels("users"), worker_id);
  const title = `${user.username}'s work (${friendlySeason()})`;

  const user_work_page = {
    tab: 0,
    earned_tab: 1,
    user: user,
    date: new Date(),
  };

  return { title, viewShown: "UserWork", user_work_page };
};

const workerRouteReceived = (worker_id_str: string): RouterState => {
  const worker_id = parseInt(worker_id_str);
  const user = findModelById(getModels("users"), worker_id);
  const title = `${user.username}'s work (${friendlySeason()})`;

  const user_work_page = {
    tab: 2,
    earned_tab: 0,
    user: user,
    date: new Date(),
  };

  return { title, viewShown: "UserWork", user_work_page };
};

const profileRoute = (): RouterState => {
  const user = store.getNonNullState("currentUser");
  const full_profile_form = getFullProfileFormState(user);

  return {
    title: "Update Your Profile",
    viewShown: "Profile",
    full_profile_form,
  };
};

const statsRoute = (): RouterState => {
  const stats = { tab: 0 };
  return { title: `Stats (${friendlySeason()})`, viewShown: "Stats", stats };
};

const routeMappers = {
  "/": rootRoute,
  "/jobs/search/:search": searchJobsRoute,
  "/login": loginRoute,
  "/signup": signupRoute,
  "/jobs": jobsRoute,
  "/stats": statsRoute,
  "/jobs/:id": jobDetailRoute,
  "/tickets/:id/room": ticketRoomRoute,
  "/buildings/:id": buildingDetailRoute,
  "/basic_info": basicInfoRoute,
  "/bonus_bucks": bonusBucksRoute,
  "/truck_loads": truckLoadsRoute,
  "/truck_loads/:id": truckLoadDetailRoute,
  "/cheatsheet": cheatSheetRoute,
  "/payscales": payscalesRoute,
  "/needs_attention_tickets": ticketsRoute,
  "/your_work": yourWorkRoute,
  "/workers": workersRoute,
  "/workers/:id": workerRoute,
  "/profile": profileRoute,
  "/workers/:id/rooms/:id": workerRoomRoute,
  "/workers/:id/bonus_bucks": workerRouteBonusBucks,
  "/workers/:id/daily_earnings": workerRouteDailyEarnings,
  "/workers/:id/received": workerRouteReceived,
  "/payscales/:id": payscaleDetailRoute,
};

export const transformState = (
  {
    home_note,
    midyear_user_ids,
    brich_user_ids,
    season,
    colormode,
    working,
    drawerOpen,
    currentUser,
    collapsed_buildings,
    collapsed_dates,
    school_year,
  }: AppState,
  state: RouterState,
): AppState => {
  const nextState: AppState = {
    home_note,
    midyear_user_ids,
    brich_user_ids,
    season,
    colormode,
    working,
    drawerOpen,
    currentUser,
    collapsed_buildings,
    collapsed_dates,
    school_year,
  };
  Object.assign(nextState, state);
  return nextState;
};

const handler = (state: RouterState): void => {
  const store = getStore();

  const nextState = transformState(store.state, state);
  store.state = nextState;
  store.forceUpdate("viewShown");
};

const badRouterHandler = (): RouterState => {
  return { viewShown: "BadRoute" };
};

export type RouterState = Required<Partial<AppState>, "viewShown">;

export const router = new StateRouter<RouterState>({
  routeMappers,
  handler,
  badRouterHandler,
});
