import { Store } from "set-state-is-great";
import StateRouter from "../libs/StateRouter";
import { AppState } from "../types/state_types";
import { RouterState } from "../init_app/router";
import Upgrade from "../models/Upgrade";
import Product from "../models/Product";
import School from "../models/School";
import Building from "../models/Building";
import User from "../models/User";
import InstallAction from "../models/InstallAction";
import Install from "../models/Install";
import Room from "../models/Room";
import Filter from "../models/Filter";
import Warehouse from "../models/Warehouse";
import TruckLoad from "../models/TruckLoad";
import BonusBuck from "../models/BonusBuck";
import Payscale from "../models/Payscale";
import FieldNote from "../models/FieldNote";
import RoomLocking from "../models/RoomLocking";
import Ticket from "../models/Ticket";
import StripeCharge from "../models/StripeCharge";
import Reservation from "../models/Reservation";
import Paycheck from "../models/Paycheck";
import Vehicle from "../models/Vehicle";

export const seasonStorageKey = "season3";

export const data: Partial<AllModels> = {};
export let store: Store<AppState>;
export let router: StateRouter<RouterState>;

export const getStore = (): Store<AppState> => {
  return store;
};

export function getAllData(): Partial<AllModels> {
  return data;
}

export type AllModels = {
  upgrades: Upgrade[];
  products: Product[];
  rooms: Room[];
  schools: School[];
  buildings: Building[];
  users: User[];
  installs: Install[];
  warehouses: Warehouse[];
  vehicles: Vehicle[];
  truck_loads: TruckLoad[];
  install_actions: InstallAction[];
  bonus_bucks: BonusBuck[];
  payscales: Payscale[];
  field_notes: FieldNote[];
  room_lockings: RoomLocking[];
  stripe_charges: StripeCharge[];
  tickets: Ticket[];
  paychecks: Paycheck[];
  reservations: Reservation[];
};

export const setGlobals = (
  theStore: Store<AppState>,
  theRouter: StateRouter<RouterState>,
  filter: Filter | null,
): void => {
  router = theRouter;
  store = theStore;

  window.App = {
    router,
    store,
    filter,
  };
};

export function setModels<Key extends keyof AllModels>(
  key: Key,
  models: AllModels[Key],
): AllModels[Key] {
  data[key] = models;

  return models;
}

export function getModels<Key extends keyof AllModels>(
  key: Key,
): NonNullable<Partial<AllModels>[Key]> {
  const models = data[key];

  if (models) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return models!;
  } else {
    throw new Error(`model ${key} missing`);
  }
}
