import { Box, Link } from "@material-ui/core";
import { sortInstallsByShort } from "../../helpers/install_helpers";
import { isIos } from "../../util/app_util";
import PhoneIcon from "@material-ui/icons/Phone";
import MessageIcon from "@material-ui/icons/Message";
import InstallButton from "./InstallButton";
import React from "react";
import Reservation from "../../models/Reservation";
import Room from "../../models/Room";
import { getDayMonth } from "../../util/util";
import { colors } from "../../constants/colors";

export const ReservationRow = ({
  reservation,
  room,
}: {
  reservation: Reservation;
  room: Room;
}): JSX.Element | null => {
  const key = `reservation_row_${reservation.id}`;

  let installs = room.installs!.filter((i) => i.reservation_id === reservation.id);

  if (installs.length === 0) {
    return null;
  }

  installs = sortInstallsByShort(installs);

  const student = reservation.studentDeets();

  const school = room.building.school;
  const school_name = school.short === "wm" ? `William %26 Mary` : school.name;
  const msg = `Hi ${
    student.first_name
  }, this is DormsDirect 👋.  We are contacting you about your rental items for ${room
    .roomStr()
    .replace(",", "")} at ${school_name}`;

  const msgBody = isIos() ? `&body=${msg}` : `?body=${msg}`;

  const arrival_date = reservation.arrival_date_at
    ? getDayMonth(reservation.arrival_date_at)
    : null;

  const departure_date = reservation.departure_date
    ? getDayMonth(reservation.departure_date)
    : null;

  const res_date = reservation.created_at;
  const payment_date = reservation.getLatestPaymentDate();

  return (
    <div key={key} className="mt-4">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div className="d-flex align-items-center">products for {student.full_name} </div>

        <div>
          <Link href={`sms:${student.phone}`} className="mx-3">
            <PhoneIcon />
          </Link>
          <Link href={`sms:${student.phone}${msgBody}`} className="mx-3">
            <MessageIcon />
          </Link>
        </div>
      </Box>

      <div className="d-flex flex-wrap">
        {installs.map((i) => (
          <InstallButton className="mx-1 mt-2" key={`install_button_${i.id}`} install={i} />
        ))}
      </div>
      <div>
        {student.special_instructions?.trim() ? (
          <div>
            <b>SPECIAL INSTRUCTIONS: </b>
            <div style={{ color: colors.danger }}>{student.special_instructions}</div>
          </div>
        ) : null}

        {departure_date ? (
          <div style={{ marginBottom: 10 }}>
            <b>departure date: </b>
            {departure_date}
          </div>
        ) : null}

        {arrival_date ? (
          <div>
            <b>arrival date: </b>
            {arrival_date}
          </div>
        ) : null}

        {payment_date ? (
          <div>
            <b>payment date: </b>
            {getDayMonth(payment_date)}
          </div>
        ) : null}

        {res_date ? (
          <div>
            <b>reservation date: </b>
            {getDayMonth(res_date)}
          </div>
        ) : null}
      </div>
    </div>
  );
};
