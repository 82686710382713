import TruckLoad from "../models/TruckLoad";
import { TruckLoadModalState } from "../types/state_types";
import { store } from "../constants/globals";

export const getTruckLoadModalState = (truck_load: TruckLoad | null): TruckLoadModalState => {
  return {
    working: false,
    errors: [],

    id: truck_load?.id || null,
    bl_count: truck_load?.bl_count || 0,
    ffc_count: truck_load?.ffc_count || 0,
    mf_count: truck_load?.mf_count || 0,
    sr_count: truck_load?.sr_count || 0,
    ft_count: truck_load?.ft_count || 0,
    dft_count: truck_load?.dft_count || 0,
    bs_count: truck_load?.bs_count || 0,
    lbx_count: truck_load?.lbx_count || 0,
    pm_count: truck_load?.pm_count || 0,
    tv32_count: truck_load?.tv32_count || 0,
    tv40_count: truck_load?.tv40_count || 0,
    bb_count: truck_load?.bb_count || 0,
    dsk_count: truck_load?.dsk_count || 0,
    hbw_count: truck_load?.hbw_count || 0,
    hbl_count: truck_load?.hbl_count || 0,
    notes: truck_load?.notes || "",
    building_ids: truck_load?.building_ids || [],
    worker_ids: truck_load?.worker_ids || [],
    driver_id: truck_load?.driver_id || null,
    warehouse_id: truck_load?.warehouse_id || null,
    vehicle_id: truck_load?.vehicle_id || null,
    type: truck_load?.type || "dropoff",
    performed_at: truck_load?.performed_at || new Date(),
  };
};

export const setTruckLoadModalState = (truck_load: TruckLoad | null): void => {
  const state = getTruckLoadModalState(truck_load);
  store.setState("truck_load_modal", state);
};
