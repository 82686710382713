import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { seasonStorageKey, store } from "../../constants/globals";
import { seasons } from "../../init_app/store";

const handleChange = (e: React.ChangeEvent<{ value: unknown }>): void => {
  let season = e.target.value as string;

  season = seasons.includes(season) ? season : "2023_installs";

  localStorage.setItem(seasonStorageKey, season);

  window.location.reload();
};

const ChangeSeasonSelect = (): JSX.Element => {
  const id = `change-season-select`;
  const season = store.getState("season");

  return (
    <FormControl variant="outlined" className="mt-2">
      <InputLabel htmlFor={id}>Select Season</InputLabel>
      <Select id={id} native value={season} onChange={handleChange} label="Select Season">
        <option value="2020_installs">2020 installs</option>
        <option value="2021_installs">2021 installs</option>
        <option value="2021_removals">2021 removals</option>
        <option value="2022_installs">2022 installs</option>
        <option value="2022_removals">2022 removals</option>
        <option value="2023_installs">2023 installs</option>
        <option value="2023_removals">2023 removals</option>
        <option value="2024_installs">2024 installs</option>
      </Select>
    </FormControl>
  );
};

export default ChangeSeasonSelect;
