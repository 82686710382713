import React from "react";
import Box from "@material-ui/core/Box";
import Building from "../../models/Building";
import { router, store } from "../../constants/globals";
import { RoomTotals, InstallStats } from "../../helpers/stats_helpers";
import { install_status_colors } from "../../constants/colors";
import { stats_shorts } from "../../constants/shorts";
import { spliceFromArray } from "../../util/util";
import Ticket from "../../models/Ticket";
import strftime from "strftime";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ReactMouseEvent } from "../../types/types";
import { resetListAtIndex } from "./JobsList";

const clickBuilding = (building_id: number): void => {
  router.go(`/buildings/${building_id}`);
};
let key1 = 99;

const renderSpans = (totals: RoomTotals, short: string): JSX.Element | null => {
  if (!totals) return null;

  const total = totals.total;
  if (!total) return null;

  const { pending_delivery, delivered, pending_removal, uninstalled } = totals;

  return (
    <span key={`span${(key1 += 1)}`}>
      <span className="mx-1">
        {`${short}: `}
        <span style={{ color: install_status_colors.pending_delivery }}>{pending_delivery}</span>,
        <span style={{ color: install_status_colors.delivered }}>{delivered}</span>,
        <span style={{ color: install_status_colors.pending_removal }}>{pending_removal}</span>,
        <span style={{ color: install_status_colors.uninstalled }}>{uninstalled}</span>
      </span>
    </span>
  );
};

type BuildingRowProps = {
  index: number;
  style?: React.CSSProperties;
  building: Building;
  stats: InstallStats;
};

const renderTicket = (ticket: Ticket): JSX.Element => {
  const key = `building-row-ticket-${ticket.id}`;
  const created_at_str = strftime("%b %e %l:%M %p", ticket.created_at);
  const txt = `"${ticket.text}"`;

  return (
    <div key={key} className="d-flex align-items-center">
      <div>{txt}</div>

      <div>
        by {ticket.creator.username} @ {created_at_str}
      </div>
    </div>
  );
};

export const renderBuildingRowEle = (props: BuildingRowProps): JSX.Element => {
  const { index, building, stats, style } = props;

  const collapsed = store.getState("collapsed_buildings").includes(building);

  const tickets = building.getUnresolvedTickets();

  return (
    <Box className="pl-2" style={{ width: "100%" }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <span style={{ fontStyle: "italic" }}>
          {building.shown_name}
          {tickets.length ? ` (${tickets.length})` : null}
        </span>

        <div style={{ fontSize: 13 }} className="d-flex flex-wrap">
          {stats_shorts.map((s) => renderSpans(stats[s], s))}
        </div>

        <button
          style={{
            height: 50,
          }}
          className="My-Mui-Button-root My-Mui-ButtonBase-root"
          onClick={
            style
              ? (e: ReactMouseEvent): void => {
                  e.preventDefault();
                  e.stopPropagation();

                  const collapsed_buildings = store.getState("collapsed_buildings");

                  if (collapsed_buildings.includes(building)) {
                    spliceFromArray(collapsed_buildings, building);
                  } else {
                    collapsed_buildings.push(building);
                  }

                  resetListAtIndex(index);
                  store.forceUpdate("collapsed_buildings");
                }
              : undefined
          }
        >
          {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </button>
      </Box>
      {collapsed ? <div>{tickets.map(renderTicket)}</div> : null}
    </Box>
  );
};

const BuildingRow = (props: BuildingRowProps): JSX.Element => {
  const { building, style } = props;

  return (
    <div
      title={building.shown_name}
      onClick={(): void => {
        clickBuilding(building.id);
      }}
      style={style}
      className="d-flex align-items-center building-row"
    >
      {renderBuildingRowEle(props)}
    </div>
  );
};

export default BuildingRow;
