import ApplicationModel from "./ApplicationModel";
import { ClassName } from "../types/types";
import { ProductJSON } from "../types/json_types";
import Upgrade from "./Upgrade";
import { getModels } from "../constants/globals";
import sortBy from "lodash/sortBy";

class Product extends ApplicationModel {
  name: string;
  short: string;
  short_short: string;
  archived: boolean;
  upgrades?: Upgrade[];

  constructor(json: ProductJSON) {
    super(json);

    this.archived = json.archived;
    this.name = json.name;
    this.short = json.short;
    this.short_short = json.short_short;
  }

  key(): string {
    return `Product_${this.id}`;
  }

  hasUpgrade(): boolean {
    return this.getUpgrades().length > 0;
  }

  getUpgrades(): Upgrade[] {
    if (this.upgrades) {
      return this.upgrades;
    } else {
      const upgrades = getModels("upgrades").filter(
        (u: Upgrade): boolean => u.product_id === this.id,
      );
      this.upgrades = sortBy(upgrades, (u) => u.id);
      return upgrades;
    }
  }

  getClassName(): ClassName {
    const className: ClassName = "Product";
    return className;
  }
}

export default Product;
