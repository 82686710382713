import Product from "../models/Product";
import { InstallStatus } from "../models/Install";
import { InstallActionType } from "../models/InstallAction";
import { TruckLoadType } from "../models/TruckLoad";
import { PayscaleType, PayscaleGroup } from "../models/Payscale";
import { SchoolYearJson } from "./state_types";

export interface ModelJSON {
  id: number;
  created_at: string;
}

export interface StudentJSON extends ModelJSON {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  school_id: number;
  installs_count: number;
  dd3: boolean;
  dd4: boolean;
}

export interface ReservationJSON {
  id: number;
  student_first_name: string;
  student_last_name: string;
  student_email: string;
  student_phone: string;
  student_id: number;
  school_id: number;
  room_id: number;
  special_instructions: string;
  arrival_date_at: string | null;
  departure_date: string | null;
  has_invoice: boolean;
  created_at: string;
}

export interface PaycheckJSON extends ModelJSON {
  date_at: string;
  type: string;
  notes: string | null;
  check_number: string;
  amount_cents: number;
  payee_id: number;
  creator_id: number;
}

export type SentPaycheckJSON = {
  date_at: Date;
  type: string;
  notes: string | null;
  check_number: string | null;
  amount: string | null;
  payee_id: number | null;
};

export interface TicketJSON extends ModelJSON {
  room_id: number;
  text: string;
  creator_id: number;
  resolved_by_id: number | null;
  resolved_at: string | null;
  resolution_notes: string | null;
}

export interface FieldNoteJSON extends ModelJSON {
  room_id: number;
  note: string;
  creator_id: number;
  image_file_name: string | null;
}

export interface InstallActionCreationJSON {
  install_status: InstallStatus;
  install_actions: Array<InstallActionJSON> | null;
}

export interface InstallActionJSON extends ModelJSON {
  set: boolean;
  type: InstallActionType;
  creator_id: number;
  actor_id: number;
  install_id: number;
  splitter_ids: number[];
}

export interface UserJSON extends ModelJSON {
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  role: string;
  phone: string;
  address1: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  ssn: string | null;
  agreed_to_terms: boolean;
  active: boolean;
}

export interface WarehouseJSON extends ModelJSON {
  name: string;
}

export interface VehicleJSON extends ModelJSON {
  name: string;
}

export interface TruckLoadJSON extends ModelJSON {
  bl_count: number | null;
  ffc_count: number | null;
  mf_count: number | null;
  sr_count: number | null;
  ft_count: number | null;
  dft_count: number | null;
  bs_count: number | null;
  lbx_count: number | null;
  pm_count: number | null;
  dsk_count: number | null;
  bb_count: number | null;
  tv32_count: number | null;
  tv40_count: number | null;
  hbl_count: number | null;
  hbw_count: number | null;
  notes: string;
  building_ids: number[];
  worker_ids: number[];
  creator_id: number;
  school_id: number;
  driver_id: number | null;
  warehouse_id: number | null;
  vehicle_id: number | null;
  type: TruckLoadType;
  performed_at: string;
}

export type TruckLoadRespJSON = {
  truck_load: TruckLoadJSON;
};

export type UserJSONResponse = {
  currentUser: UserJSON;
};

export type CurrentUserJSONResponse = {
  currentUser: UserJSON | null;
};

export type ErrorResponse = {
  errors: Array<string>;
};

export interface ProductJSON extends ModelJSON {
  archived: boolean;
  name: string;
  short: string;
  short_short: string;
}

export interface SchoolJSON extends ModelJSON {
  name: string;
  short: string;
}

export interface BuildingJSON extends ModelJSON {
  name: string;
  shown_name: string;
  notes: string | null;
  gmaps_link: string | null;
  school_id: number;
}

export interface UpgradeJSON extends ModelJSON {
  product_id: number;
  product?: Product;
  short: string;
  name: string;
}

export type TicketRespJSON = {
  room: RoomJSON;
  ticket: TicketJSON;
};

export interface RoomJSON extends ModelJSON {
  tickets_count: number;
  unresolved_tickets_count: number;
  field_notes_count: number;
  installs_count: number;
  number: string;
  wing: string;
  occupied: boolean;
  locked: boolean | null;
  building_id: number;
}

export interface StudentJSON extends ModelJSON {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  school_id: number;
  installs_count: number;
}

export interface InstallJSON extends ModelJSON {
  dd3: boolean;
  dd4: boolean;
  product_id: number;
  needs_removal: boolean;
  room_id: number;
  quantity: number;
  student_id: number;
  invoice_id: number;
  reservation_id: number | null;
  line_item_id: number;
  upgrade_ids: number[];
  status: InstallStatus;
}

export type SentBonusBuckJSON = {
  worker_ids: number[];
  dollars: number;
  confirmed: boolean;
  reason: string | null;
  performed_at: Date;
};

export type BonusBuckJSON = {
  id: number;
  worker_ids: number[];
  creator_id: number;
  performed_at: string;
  dollars: number;
  confirmed: boolean;
  image_file_name: string | null;
  reason: string | null;
  created_at: string;
};

export interface PayscaleJSON extends ModelJSON {
  label: string;
  type: PayscaleType;
  group: PayscaleGroup;
  bl_cents: number | null;
  pm_cents: number | null;
  mf_cents: number | null;
  sr_cents: number | null;
  ft_cents: number | null;
  bs_cents: number | null;
  lbx_cents: number | null;
  ffc_cents: number | null;
  tv_cents: number | null;
  bb_cents: number | null;
  dsk_cents: number | null;
  hbw_cents: number | null;
  hbl_cents: number | null;
}

export interface RoomLockingJSON extends ModelJSON {
  locked: boolean | null;
  room_id: number;
  set_by_id: number;
}

export type InvoiceJSON = {
  reservation_id: number;
};

export type StripeChargeJSON = {
  reservation_id: number;
  created_at: string;
};

export type AllJSONResponse = {
  auth: {
    currentUser: UserJSON | null;
  } | null;
  data: AllModelsJSONResponse | null;
};

type AccountJSON = {
  midyear_user_ids: number[];
  home_note: string;
};

export type AllModelsJSONResponse = {
  accounts?: AccountJSON[];
  school_year: SchoolYearJson;
  upgrades: UpgradeJSON[];
  vehicles: VehicleJSON[] | null;
  paychecks: PaycheckJSON[];
  schools: SchoolJSON[];
  products: ProductJSON[];
  buildings: BuildingJSON[];
  installs: InstallJSON[];
  users: UserJSON[];
  rooms: RoomJSON[];
  install_actions: InstallActionJSON[];
  warehouses: WarehouseJSON[];
  truck_loads: TruckLoadJSON[] | null;
  bonus_bucks: BonusBuckJSON[] | null;
  payscales: PayscaleJSON[] | null;
  reservations: ReservationJSON[];
  field_notes: FieldNoteJSON[] | null;
  room_lockings: RoomLockingJSON[] | null;
  tickets: TicketJSON[] | null;
  invoices: InvoiceJSON[] | null;
  stripe_charges: StripeChargeJSON[] | null;
};

export type TableName = keyof AllModelsJSONResponse;

export function isErrorResponse<T>(json: T | ErrorResponse): json is ErrorResponse {
  return (json as ErrorResponse).errors !== undefined;
}
